//SMOOTHSCROLL TRIGGER
$('html').smoothScroll(600);

//DYNAMIC GRAYSCALE 
(function($) {
		var $window = $(window),
        $itemID = $('header');

		function resize() {
        if ($window.width() < 800) {
            return $itemID.removeClass('grayscale grayscale-fade');
        }

        $itemID.addClass('grayscale grayscale-fade');
		}

		$window
        .resize(resize)
        .trigger('resize');
})(jQuery);

//SLICK SLIDER
$(document).ready(function(){
  $('.slick').slick({
	mobileFirst: true,	
	dots: true,
	//infinite: true,
	rows: 1,
    arrows: false,
    centerMode: true,
	centerPadding: '40px',
    slidesToShow: 1,
	slidesToScroll: 1,
	responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
		slidesToScroll: 1,
		//arrows: false,
        //centerMode: true,
        //centerPadding: '40px',
      }
    },
	 {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
		slidesToScroll: 2,
	    rows: 3,
		arrows: true,
		dots: false,
		infinite: false,
		centerMode: false,
      }
    },
  ]
  });
});