var $contact = $('#contact-form-wrapper');
var $contactForm = $('#contact-form');
	$contactForm.submit(function(e) {
		e.preventDefault();
	});
	$contactForm.on("formvalid.zf.abide", function(ev,frm) {
		$.ajax({
			url: '//formspree.io/pagesailor@gmail.com',
			method: 'POST',
			data: $(this).serialize(),
			dataType: 'json',
			beforeSend: function() {
				$contact.find('.alert--error').remove();
				$contact.append('<div class="alert alert--loading"><span lang="en">Sending message…</span><span lang="hu">Üzenet küldése...</span></div>');
			},
			success: function(data) {
				$contact.find('.alert--loading').remove();
				$contact.append('<div class="alert alert--success"><span lang="en">Message sent! I will get in contact soon.</span><span lang="hu">Üzenet elküldve! Köszönöm, hamarosan jelentkezem.</span></div>');
				$contactForm.fadeOut(200);
			},
			error: function(err) {
				$contact.find('.alert--loading').remove();
				$contact.find('.alert--error').remove();
				$contact.append('<div class="alert alert--error callout"><span lang="en">Oops, there was an error.</span><span lang="hu">Ajjaj, valami hiba történt.</span></div>');
			}
		});
	});
